<template>
  <div>
    <r-page-modal
      :prompt-on-leave="true"
      v-if="isModalReleaseNote"
      @hide="closeReleaseModal"
      :is-modal="true"
      key="modalKey"
      class="modal-allowed-capacity"
      remove-scrolling
    >
      <template #page-content>
        <div class="container">
          <div class="release-modal-img">
            <img src="../../assets/images/release-img.png" alt="" />
          </div>

          <div class="mt-5">
            <h2 class="has-text-centered">New Feature</h2>
          </div>

          <div class="content is-normal px-4" style="max-width: 460px">
            <span
              style="white-space: pre"
              v-html="releaseNote.description"
            ></span>
          </div>

          <div class="px-3">
            <label class="checkbox">
              <input v-model="isRelease" type="checkbox" />
              Don't show me again
            </label>
          </div>

          <div class="buttons mt-3">
            <b-button
              :disabled="!isRelease"
              @click="onConfirmModal"
              type="is-primary"
              expanded
            >
              Confirm
            </b-button>
          </div>
        </div>
      </template>
    </r-page-modal>
  </div>
</template>

<script>
export default {
  props: ['isModalReleaseNote', 'releaseNote'],

  data() {
    return {
      isRelease: false,
    }
  },

  methods: {
    closeReleaseModal() {
      this.$emit('closeModal')
    },

    onConfirmModal() {
      const event = {
        isRelease: this.isRelease,
      }
      this.$emit('onConfirm', event)
    },
  },
}
</script>
