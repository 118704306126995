<template>
  <div>
    <div v-if="!isLoadingFaceApiModel && isFaceApiModelLoaded">
      <FaceCam
        @submit="compareFaceReg"
        :submit-loading="isComparingFaceReg"
        :is-capture-finished="isFinishedComparingFaceReg"
        :is-close-face-cam="isCloseFaceCam"
        @close="$emit('close')"
      ></FaceCam>
    </div>
    <div v-else class="container has-background-white p-6">
      <p>Loading Face API models...</p>
    </div>
  </div>
</template>

<script>
import FaceCam from './FaceCam'
import * as faceapi from 'face-api.js'
import { mapGetters } from 'vuex'
import { showToast } from '../services/util'
export default {
  props: {
    imgUrl: String,
    isCloseFaceCam: Boolean,
  },
  computed: {
    ...mapGetters({
      token: 'auth/currentAccessToken',
    }),
  },
  components: { FaceCam },
  data() {
    return {
      descriptors: { desc1: null, desc2: null, desc3: null },
      isComparingFaceReg: false,
      isFinishedComparingFaceReg: false,
      isLoadingFaceApiModel: false,
      isFaceApiModelLoaded: false,
    }
  },
  methods: {
    async compareFaceReg(image) {
      this.isComparingFaceReg = true
      this.isFinishedComparingFaceReg = false
      let that = this

      await this.toDataURL(
        `${process.env.VUE_APP_API_URL}/api/space-roketin/user/face-reg-img`,
        async function (dataUrl) {
          let img = document.createElement('img')
          img.src = dataUrl

          that.descriptors[`desc${1}`] = await faceapi.computeFaceDescriptor(
            img
          )

          const input = await faceapi.fetchImage(image)
          that.descriptors[`desc${2}`] = await faceapi.computeFaceDescriptor(
            input
          )

          let result = that.updateResult()
          that.isComparingFaceReg = false
          that.isFinishedComparingFaceReg = true

          that.$emit('result', result)
        },
        async function (status) {
          if (status !== 200) {
            that.$emit('failed', status)
          }
        }
      )
    },
    updateResult() {
      const threshold = parseFloat(
        process.env.VUE_APP_EUCLIDEAN_DISTANCE_THRESHOLD
      )

      let distance = faceapi.utils.round(
        faceapi.euclideanDistance(
          this.descriptors.desc1,
          this.descriptors.desc2
        )
      )
      if (distance > threshold) {
        // no match
        return false
      }
      return true
    },
    toDataURL(url, successCallback, failedCallback) {
      var xhr = new XMLHttpRequest()
      xhr.onload = function () {
        if (this.status == 200) {
          var reader = new FileReader()
          reader.onloadend = async function () {
            successCallback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        } else {
          failedCallback(this.status)
        }
      }

      xhr.open('GET', url)
      xhr.setRequestHeader('Authorization', `Bearer ${this.token}`)
      xhr.responseType = 'blob'
      xhr.send()
    },
    async loadFaceApiModel() {
      this.isLoadingFaceApiModel = true
      try {
        await faceapi.loadFaceRecognitionModel('models')
        this.isFaceApiModelLoaded = true
      } catch (err) {
        showToast('Error load FaceAPI model', 'is-danger', 'is-top')
        this.$emit('close')
      }
      this.isLoadingFaceApiModel = false
    },
  },
  mounted() {
    this.loadFaceApiModel()
  },
}
</script>

<style scoped></style>
