<template>
  <div>
    <b-table
      :data="data"
      class="rise-table-attendance"
      height="388px"
      overflow="auto"
      :sticky-header="true"
    >
      <b-table-column
        field="description"
        label="Description"
        cell-class="rise-table-column-activity-date"
        v-slot="props"
      >
        {{
          props.row.attendanceActivityDescription
            ? props.row.attendanceActivityDescription
            : '-'
        }}
      </b-table-column>

      <b-table-column
        field="activity_duration"
        label="Activity Duration"
        centered
        cell-class="rise-table-column-activity-duration"
        v-slot="props"
      >
        {{
          props.row.activityDuration
            ? formatDurationSecond(props.row.activityDurationSecond)
            : '-'
        }}
      </b-table-column>
      <b-table-column field="action" label="Action" centered v-slot="props">
        <b-button
          :icon-left="props.row.isPlayable ? 'play' : 'pause'"
          type="is-command"
          :loading="props.row.isLoading"
          @click.native="
            toggleActivity(
              props.row,
              props.index,
              props.row.isPlayable ? 'play' : 'pause'
            )
          "
        />
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: ['data'],
  methods: {
    toggleActivity(row, index) {
      const data = {
        index: index,
        rows: row,
      }
      this.$emit('toggle', data)
    },

    formatDurationSecond(durationSecond) {
      let hour = Math.floor(durationSecond / 3600)
      let min = Math.floor((durationSecond % 3600) / 60)
      let second = Math.floor((durationSecond % 3600) % 60)

      return `${hour}h ${min}m ${second}s`
    },
  },
}
</script>
