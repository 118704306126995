<template>
  <r-card>
    <div class="rise-card-title">Calendar</div>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Personal">
        <PersonalCalendarTab
          :calendarPersonal="calendarPersonal"
          :calendarSelectedPersonal="calendarSelectedPersonal"
        ></PersonalCalendarTab>
      </b-tab-item>
      <b-tab-item label="Teams">
        <TeamCalendarTab
          :calendarTeam="calendarTeam"
          :calendarSelectedTeam="calendarSelectedTeam"
        ></TeamCalendarTab>
      </b-tab-item>
    </b-tabs>
  </r-card>
</template>

<script>
import FullCalendar from '@fullcalendar/vue' // eslint-disable-line
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment-timezone'
import ApiService from '../services/common/api.service'
import { mapActions } from 'vuex'
import PersonalCalendarTab from './Rise/PersonalCalendarTab.vue'
import TeamCalendarTab from './Rise/TeamCalendarTab.vue'

export default {
  components: {
    TeamCalendarTab,
    PersonalCalendarTab,
  },
  watch: {
    async activeMonthPersonal(value) {
      await this.getAllTimeOffs(value).then((result) => {
        this.tempDataTimeOff = result
      })
      this.mapDataTimeOff()
    },
    async activeMonthTeam(value) {
      await this.getAllTimeOffs(value).then((result) => {
        this.tempDataTimeOff = result
      })
      this.mapDataTimeOff()
    },
  },
  data() {
    return {
      activeTab: 0,

      // calendar time oft
      dateStrNow: moment().utc(new Date()).local().format('YYYY-MM-DD'),
      tempDataTimeOff: [],
      dataTimeOffForUpcomingEvents: [],
      activeMonthPersonal: null,
      activeMonthTeam: null,

      // API methods for FullCalendar
      calendarTeam: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        showNonCurrentDates: false,
        eventDisplay: 'list-item',
        selectable: true,
        // // method that will be triggered when user change months
        datesSet: (dateInfo) => {
          const month = moment(dateInfo.startStr).format('M')
          const year = moment(dateInfo.startStr).format('YYYY')
          const activeMonth = [+month, +year]
          if (
            JSON.stringify(this.activeMonthTeam) !== JSON.stringify(activeMonth)
          ) {
            this.activeMonthTeam = [+month, +year]
          }
        },
        dayHeaderClassNames: 'rise-calendar-header',
        dayCellClassNames: 'rise-calendar-cell',
        dateClick: (info) => {
          this.dateClickTeam(info.dateStr)
        },

        eventColor: '#B100FF',
        events: [],
      },

      calendarPersonal: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        showNonCurrentDates: false,
        eventDisplay: 'list-item',
        selectable: true,
        // // method that will be triggered when user change months
        datesSet: (dateInfo) => {
          const month = moment(dateInfo.startStr).format('M')
          const year = moment(dateInfo.startStr).format('YYYY')
          const activeMonth = [+month, +year]
          if (
            JSON.stringify(this.activeMonthPersonal) !==
            JSON.stringify(activeMonth)
          ) {
            this.activeMonthPersonal = [+month, +year]
          }
        },
        dayHeaderClassNames: 'rise-calendar-header',
        dayCellClassNames: 'rise-calendar-cell',
        dateClick: (info) => {
          this.dateClickPersonal(info.dateStr)
        },

        eventColor: '#B100FF',
        events: [],
      },

      calendarSelectedPersonal: null,
      calendarSelectedTeam: null,
    }
  },
  methods: {
    ...mapActions({
      getAllTimeOffs: 'timeOffModule/getAllTimeOffs',
    }),
    // to check whether the date is today
    isDateToday(date) {
      if (moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
        return true
      } else {
        return false
      }
    },

    displayEventsDate(start, end) {
      if (end && start !== end) {
        const startsMonth = moment(start).format('MM')
        const endsMonth = moment(end).format('MM')

        if (startsMonth === endsMonth) {
          return `${moment(start).format('DD')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        } else {
          return `${moment(start).format('DD MMM YYYY')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        }
      }
      return moment(start).format('D MMMM YYYY')
    },

    displayChangeScheduleDate(start, end) {
      if (end && start !== end) {
        const startsMonth = moment(start).format('MM')
        const endsMonth = moment(end).format('MM')

        if (startsMonth === endsMonth) {
          return `${moment(start).format('DD MMM')} to ${moment(end).format(
            'DD MMM YYYY'
          )}`
        } else {
          return `${moment(start).format('DD MMM YYYY')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        }
      }
      return moment(start).format('D MMMM YYYY')
    },

    mapDataTimeOff() {
      const myTimeOff = this.tempDataTimeOff.events.map((el) => ({
        title: el.eventName,
        start: moment.utc(el.startDate).local().format('YYYY-MM-DD'),
        end: moment.utc(el.endDate).local().format('YYYY-MM-DD'),
        type: el.type,
        time: el.time,
      }))

      this.tempDataTimeOff.teamEvents = this.tempDataTimeOff.teamEvents.filter(
        function (el) {
          if (el?.type === 'Global Time off') {
            return true
          } else {
            return el?.status?.toLowerCase() == 'approved' ? true : false
          }
        }
      )

      let teamTimeOff = []
      if (this.tempDataTimeOff.teamEvents.length > 0) {
        teamTimeOff = this.tempDataTimeOff.teamEvents.map((el) => ({
          title: el.eventName,
          start: moment.utc(el.startDate).local().format('YYYY-MM-DD'),
          end: moment.utc(el.endDate).local().format('YYYY-MM-DD'),
          type: el.type,
          name: el.firstName ?? el.eventName,
          time: el.time,
        }))
      }

      this.dataTimeOffForUpcomingEvents = [...myTimeOff, ...teamTimeOff]

      // filtering events which have the same starting date to display on calendar
      let tempMyTimeOff = myTimeOff.filter(
        (el, index, self) =>
          index === self.findIndex((t) => t.start === el.start)
      )
      let tempTeamTimeOff = teamTimeOff.filter(
        (el, index, self) =>
          index === self.findIndex((t) => t.start === el.start)
      )

      this.calendarTeam.events = [...tempTeamTimeOff]
      this.calendarPersonal.events = [...tempMyTimeOff]
    },
    // format header calendar
    formatHeaderDay(date) {
      return moment(date).format('dddd').slice(0, 1)
    },

    // format cell calendar
    formatCellContent(date) {
      return moment(date).format('D')
    },

    // get day's name
    getDaysName(date) {
      return moment(date).format('ddd')
    },

    async dateClickTeam(info) {
      let selectedCalendar = info
      let response = await ApiService.get(
        `/api/space-roketin/user/agenda/my-team?date=${selectedCalendar}`
      )

      this.calendarSelectedTeam = response.data.data

      this.calendarSelectedTeam.push({
        global_timeoffs: response.data.globalTimeoffs,
      })

      // console.log("test", response.data.globalTimeoffs);
    },

    async dateClickPersonal(info) {
      let selectedCalendar = info
      let response = await ApiService.get(
        `/api/space-roketin/user/agenda/personal?date=${selectedCalendar}`
      )

      this.calendarSelectedPersonal = response.data.data
    },
  },
  async mounted() {
    await this.dateClickTeam(this.dateStrNow)
    await this.dateClickPersonal(this.dateStrNow)
    // get time off data for calendar
    const month = moment().format('M')
    const year = moment().format('YYYY')
    await this.getAllTimeOffs([+month, +year]).then((result) => {
      this.tempDataTimeOff = result
    })
    this.mapDataTimeOff()
  },
  //
}
</script>

<style scoped></style>
