<template>
  <div>
    <b-table
      :data="data"
      class="rise-table-attendance"
      :sticky-header="stickyHeader"
      height="196px"
    >
      <b-table-column
        field="name"
        label="Name"
        cell-class="rise-table-column-date"
        v-slot="props"
      >
        {{
          props.row.lastName
            ? `${props.row.firstName} ${props.row.lastName}`
            : props.row.firstName
        }}
      </b-table-column>

      <b-table-column field="status" label="Status" centered v-slot="props">
        <span
          class="attendance-status-wfo"
          :class="
            props && props.row.isRemote
              ? 'attendance-status-wfh'
              : 'attendance-status-wfo'
          "
        >
          {{ props.row.isRemote ? 'WFH' : 'WFO' }}
        </span>
      </b-table-column>

      <b-table-column field="clock_in" label="Clock In" centered v-slot="props">
        {{ props.row.checkInTime ? formatTime(props.row.checkInTime) : '-' }}
      </b-table-column>

      <b-table-column
        field="clock_out"
        label="Clock Out"
        centered
        v-slot="props"
      >
        {{ props.row.checkOutTime ? formatTime(props.row.checkOutTime) : '-' }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment-timezone'
export default {
  props: ['data', 'stickyHeader'],
  methods: {
    formatTime(str) {
      return moment(moment.utc(str).toDate()).format('HH:mm')
    },
  },
}
</script>
