<template>
  <div>
    <r-card class="calendar-outer-card">
      <r-card class="calendar-inner-card">
        <FullCalendar
          :options="calendarTeam"
          ref="riseCalendar"
          class="rise-calendar"
        >
          <!-- header -->
          <template v-slot:dayHeaderContent="arg">
            <p class="calendar-header-date">
              {{ formatHeaderDay(arg.date) }}
            </p>
          </template>
          <!-- cell -->
          <template v-slot:dayCellContent="arg">
            <p>
              {{ formatCellContent(arg.date) }}
            </p>
            <p class="calendar-todays-day" v-if="isDateToday(arg.date)">
              {{ getDaysName(arg.date) }}
            </p>
          </template>
          <!-- event dots -->
          <template v-slot:eventContent="arg">
            <div
              class="calendar-dot"
              :class="
                arg.event.extendedProps.name
                  ? 'events-bg-team'
                  : 'events-bg-personal'
              "
            />
          </template>
        </FullCalendar>
      </r-card>
      <!-- events details -->
      <div class="calendar-upcoming-container">
        <h3 class="calendar-upcoming-title">Upcoming</h3>
        <div
          class="calendar-events-container"
          v-if="calendarSelectedTeam && calendarSelectedTeam.length > 0"
        >
          <div v-for="(item, index) in calendarSelectedTeam" :key="index">
            <div class="calendar-events-teams" v-if="item.agenda != null">
              <div class="events-detail-wrapper">
                <div
                  class="events-dot"
                  :class="
                    item.fullName ? 'events-bg-team' : 'events-bg-personal'
                  "
                />
                <div class="events-detail">
                  <p class="events-title">
                    {{ item.fullName }}
                  </p>
                  <div>
                    <div
                      v-for="(agenda, agendaIndex) in item.agenda"
                      :key="agendaIndex"
                    >
                      <p class="is-capitalized">
                        {{
                          `${agenda.requestReason} - ${
                            agenda.requestType === 'timeoff'
                              ? 'Time Off'
                              : 'Change Schedule'
                          }`
                        }}
                      </p>
                      <p
                        class="events-date"
                        v-if="agenda.requestType === 'timeoff'"
                      >
                        {{
                          displayEventsDate(agenda.startDate, agenda.endDate)
                        }}
                      </p>

                      <p class="events-date" v-else>
                        {{
                          displayChangeScheduleDate(
                            agenda.date,
                            agenda.changeDate
                          )
                        }}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else
              class="calendar-events-teams"
              v-for="(val, id) in item.global_timeoffs"
              :key="id"
            >
              <div class="events-detail-wrapper">
                <div
                  class="events-dot"
                  :class="val.name ? 'events-bg-team' : 'events-bg-personal'"
                />
                <div class="events-detail">
                  <p class="events-title">Global Time Off</p>
                  <div>
                    <div>
                      <p class="events-date">
                        {{ val.name }}
                      </p>

                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>No upcoming agenda</div>
      </div>
    </r-card>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import FullCalendar from '@fullcalendar/vue'

export default {
  props: ['calendarTeam', 'calendarSelectedTeam'],

  components: {
    FullCalendar,
  },

  methods: {
    /**
     * Format calendar header day
     * @param {Date} date - date
     * @returns {String} Formatted string day name
     */
    formatHeaderDay(date) {
      return new Date(date)
        .toLocaleDateString('en-us', { weekday: 'short' })
        .slice(0, 1)
    },

    /**
     * Format calendar content
     * @param {Date} date - date
     * @returns {Date} date
     */
    formatCellContent(date) {
      return date.getDate()
    },

    /**
     * Get days name
     * @param {Date} date - date
     * @returns {String} day's short name
     */
    getDaysName(date) {
      return new Date(date).toLocaleDateString('en-us', { weekday: 'short' })
    },

    /**
     * Check if date === today
     * @param {Date} date - date
     * @returns {Bool} true/false
     */
    isDateToday(date) {
      const today = new Date()
      if (date.toDateString() === today.toDateString()) {
        return true
      } else {
        return false
      }
    },

    displayEventsDate(start, end) {
      if (end && start !== end) {
        const startsMonth = moment(start).format('MM')
        const endsMonth = moment(end).format('MM')

        if (startsMonth === endsMonth) {
          return `${moment(start).format('DD')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        } else {
          return `${moment(start).format('DD MMM YYYY')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        }
      }
      return moment(start).format('D MMMM YYYY')
    },

    displayChangeScheduleDate(start, end) {
      if (end && start !== end) {
        const startsMonth = moment(start).format('MM')
        const endsMonth = moment(end).format('MM')

        if (startsMonth === endsMonth) {
          return `${moment(start).format('DD MMM')} to ${moment(end).format(
            'DD MMM YYYY'
          )}`
        } else {
          return `${moment(start).format('DD MMM YYYY')} - ${moment(end).format(
            'DD MMM YYYY'
          )}`
        }
      }
      return moment(start).format('D MMMM YYYY')
    },
  },
}
</script>
