<template>
  <div class="column is-12 pl-0 pr-0">
    <r-card>
      <div class="rise-card-title mb-4">My Teams Attendance</div>
      <small-table>
        <template #body>
          <my-teams-attendance-table
            :data="data"
            :stickyHeader="stickyHeader"
          ></my-teams-attendance-table>
        </template>
      </small-table>
    </r-card>
  </div>
</template>
<script>
import SmallTable from '@/components/Rise/SmallTable.vue'
import MyTeamsAttendanceTable from '@/components/Rise/MyTeamsAttendanceTable.vue'
export default {
  components: { SmallTable, MyTeamsAttendanceTable },
  props: ['data', 'stickyHeader'],
}
</script>
