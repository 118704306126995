<template>
  <r-page page-title="Rise" :key="key">
    <template #page-header>
      <h2 class="page-title">Rise</h2>
    </template>
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column is-8">
          <div class="column is-12 pt-0 pl-0 pr-0">
            <announcement-rise
              :published="announcementPublished"
            ></announcement-rise>
          </div>
        </div>
        <div class="column is-4">
          <div class="column is-12">
            <quote-component :user="user" :quotes="quoteRise"></quote-component>
          </div>
          <div class="column is-12">
            <ClockInOut
              :date="date"
              :timer="timer"
              :user="user"
              :isLoading="isLoading"
              :isLoadingLate="isLoadingLateInfo"
              :isSaving="isSavingAttendanceAdjustment"
              :isModalLateOpen="isModalFixLateAttendanceOpen"
              :lateInfo="lateInfo"
              :overworkInfo="overworkInfo"
              :isModalOverClockOutOpen="isModalOverClockOutOpen"
              :isRequestOvertime="isRequestOvertime"
              :rule="overtimeRule"
              :overtimeHour="overtimeHour"
              @reviseAttendance="reviseAttendance"
              @saveAttendance="saveAttendanceAdjustment($event)"
              @saveOvertime="saveRequestOvertime($event)"
              @clockInValidation="clockInValidation"
              @closeModalClockIn="closeModalFixLateAttendance"
              @toggleWorking="toggleWorking"
              @closeModalClockOut="closeModalOverClockOut"
            ></ClockInOut>
          </div>
        </div>
      </div>

      <!-- section attendance card -->
      <div class="columns is-multiline">
        <div class="column is-5 pt-0">
          <div class="column is-12 pl-0 pr-0 pb-0">
            <r-card>
              <div class="level">
                <div class="level-left rise-card-title">Latest Attendance</div>
                <router-link to="attendance" class="is-link">
                  See All
                </router-link>
              </div>
              <my-attendance :data="dataMyAttendance"></my-attendance>
            </r-card>
          </div>
          <my-team-attendance
            :data="dataTeamAttendance"
            :stickyHeader="stickyHeaders"
          ></my-team-attendance>
        </div>
        <!-- Calendar -->
        <div class="column is-3 rise-calendar-card">
          <PersonalAndTeamCalendar />
        </div>
        <div class="column is-4 pl-5 pr-5">
          <TodaysActivity
            @open="openModalActivityAttendance"
            @close="closeModalActivityAttendance"
            @toggleActivity="toggleActivity($event)"
            @submit="submit($event)"
            :activeLocation="activeActivityLocation"
            :activityType="activityAttendanceType"
            :activityTimer="activityAttendanceTimer"
            :isModalOpen="isModalActivityAttendanceOpen"
            :isLoading="isActivityAttendanceRiseLoading"
            :data="dataActivityAttendance"
          ></TodaysActivity>
        </div>
      </div>

      <b-modal
        v-if="user && isFaceRegMandatory"
        @close="closeModalFaceCam"
        :active="isModalFaceCamOpen"
        trap-focus
        has-modal-card
      >
        <template #default="props">
          <FaceCompare
            @result="compareAndClockIn"
            @close="props.close"
            :img-url="user.faceRegFileUrl"
            :is-close-face-cam="isCloseFaceCam"
            @failed="handleFailedCompare"
          />
        </template>
      </b-modal>
      <b-tag
        v-if="isFaqButtonActive"
        class="faq-button"
        type="is-dark"
        @close="isFaqButtonActive = false"
        closable
      >
        <p>
          <a href="faq" target="_blank" class="is-white">
            <b-icon
              icon="help-circle"
              custom-size="mdi-24px"
              class="ml-2 mr-2"
            />
            FAQ
          </a>
        </p>
      </b-tag>

      <!-- RELEASE NOTE -->
      <release-note
        :isModalReleaseNote="isModalReleaseNote"
        :releaseNote="releaseNote"
        :isRelease="isRelease"
        @closeModal="closeReleaseModal"
        @onConfirm="onConfirmModal($event)"
      ></release-note>
      <!-- <r-page-modal
        :prompt-on-leave="true"
        v-if="isModalReleaseNote"
        @hide="closeReleaseModal"
        :is-modal="true"
        key="modalKey"
        class="modal-allowed-capacity"
        remove-scrolling
      >
        <template #page-content>
          <div class="container">
            <div class="release-modal-img">
              <img src="../assets/images/release-img.png" alt="" />
            </div>

            <div class="mt-5">
              <h2 class="has-text-centered">New Feature</h2>
            </div>

            <div class="content is-normal px-4" style="max-width: 460px">
              <span
                style="white-space: pre"
                v-html="releaseNote.description"
              ></span>
            </div>

            <div class="px-3">
              <label class="checkbox">
                <input v-model="isRelease" type="checkbox" />
                Don’t show me again
              </label>
            </div>

            <div class="buttons mt-3">
              <b-button
                :disabled="setRelease"
                @click="onConfirmModal"
                type="is-primary"
                expanded
              >
                Confirm
              </b-button>
            </div>
          </div>
        </template>
      </r-page-modal> -->
    </template>
  </r-page>
</template>

<script>
import moment from 'moment-timezone'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ApiService from '../services/common/api.service'
import { convertToUTC, showToast } from '../services/util'
import AnnouncementRise from '@/components/AnnouncementRise.vue'
import MyAttendance from '@/components/MyAttendance.vue'
import MyTeamAttendance from '../components/MyTeamAttendance.vue'
import TodaysActivity from '../components/Rise/TodaysActivity.vue'
import QuoteComponent from '@/components/QuoteComponent.vue'
import FaceCompare from '../components/FaceCompare'
import PersonalAndTeamCalendar from '../components/PersonalAndTeamCalendar'
import ClockInOut from '../components/Rise/ClockInOut.vue'
import ReleaseNote from '../components/Rise/ReleaseNote.vue'

export default {
  components: {
    PersonalAndTeamCalendar,
    FaceCompare,
    AnnouncementRise,
    MyAttendance,
    MyTeamAttendance,
    TodaysActivity,
    QuoteComponent,
    ClockInOut,
    ReleaseNote,
  },

  data() {
    return {
      key: 1, // for force render r-page
      date: null,
      isLoading: false,
      duration: null,
      working: false,
      pause: false,
      workTime: 0,
      announcements: [],
      timerCount: 0,
      activityTimerCount: 0,
      activityTimerId: -1,

      activeActivityTimerCount: 0,
      activeActvitiyTimer: 0,
      activeActvitiyTimerId: 0,
      activeActivity: null,

      timer: null,
      activityDuration: null,
      activityAttendanceTimer: null,
      dataMyAttendance: [],
      dataActivityAttendance: [],
      tempDataMyAttendance: [],
      dataTeamAttendance: [],
      stickyHeaders: true,
      isModalActivityAttendanceOpen: false,
      isActivityPlayable: null,
      selectedAttendanceType: null,
      activityAttendanceType: [],
      activeActivityLocation: null,
      isPlayable: null,
      description: null,
      isSubmitting: false,
      isActivityAttendanceRiseLoading: false,
      startActivityTime: null,
      endActivityTime: null,
      firstStartActivityTime: null,
      announcementPublished: [],
      quoteRise: null,

      // for face recognition :
      isErrorFetchFaceRegMandatory: false,
      isFaceRegMandatory: false,
      isModalFaceCamOpen: false,
      faceRegCompareResult: false,
      isCloseFaceCam: false,

      isFaqButtonActive: true,

      // Modal Fix Late Attendance
      isModalFixLateAttendanceOpen: false,
      isLoadingLateInfo: false,
      lateInfo: null,

      actualClockIn: null,
      lateReason: null,
      isSavingAttendanceAdjustment: false,

      // Modal Request Over Clock Out
      isModalOverClockOutOpen: false,
      isLoadingOverworkInfo: false,
      overworkInfo: null,

      isDefaultWorkHour: 1,
      isRequestOvertime: null,
      overtimeHour: null,
      overtimeRule: null,
      overtimeReason: null,
      additionalApproverId: null,

      startTime: null,
      endTime: null,
      attendanceId: null,
      isSavingRequestOvertime: false,

      // User Autocomplete
      listUser: [],
      isFetchingUser: false,
      pageUserList: 1,
      lastPageUserList: 1,
      selectedUser: '',
      searchUser: '',

      isModalReleaseNote: false,
      isRelease: false,
    }
  },
  async beforeMount() {
    await this.checkUserDetail()
  },
  async mounted() {
    // Release Note
    this.checkReleaseNote()

    // document.title = 'rise - Rise by Roketin'
    this.date = moment().format('dddd, DD MMMM YYYY')
    const formattedDate = moment().format('YYYY-MM-DD')
    this.setActivityAttendanceRise([])
    this.setAnnouncement([])

    await this.fetchFaceRecogMandatory()
    await this.loadAnnouncement()
    await this.loadQuoteRise()
    await this.loadActivityAttendance()
    await this.getActivityAttendanceType()

    // timers initialization will run simultaneously
    await Promise.all([
      this.initTimer(formattedDate),
      this.initActivityTimer(),
      this.initActiveActivityTimer(),
    ])

    // get my attendance history
    const dateArray = new Array(4)
      .fill(1)
      .map((_, i) => moment().subtract(i, 'days').format('YYYY-MM-DD'))
    const startDate = dateArray[3]

    let param = {
      startDate: startDate,
      endDate: formattedDate,
    }

    await this.fetchAttendance(param).then((result) => {
      this.tempDataMyAttendance = result
    })
    await this.fetchMyTeamAttendance().then((result) => {
      this.dataTeamAttendance = result
    })
    dateArray.forEach((el, i) => {
      const filteredArray = this.tempDataMyAttendance.filter(
        (elem) => elem.date === el
      )
      if (filteredArray.length > 0) {
        dateArray[i] = filteredArray[0]
      }
    })
    this.dataMyAttendance = dateArray
  },
  methods: {
    ...mapActions({
      actionClockIn: 'user/checkIn',
      actionClockOut: 'user/checkOut',
      workDuration: 'user/getWorkDuration',
      totalActivityDurationPerDay:
        'activityAttendance/getTotalActivityDuration',
      fetchAttendance: 'attendance/fetchAttendance',
      fetchMyTeamAttendance: 'attendance/fetchMyTeamAttendance',
      fetchActivityAttendanceRise:
        'activityAttendance/fetchActivityAttendanceRise',
      fetchAnnouncementPublished: 'announcement/fetchAnnouncementPublished',
      fetchQuoteRise: 'quotes/fetchQuoteRise',
      fetchLateInfo: 'attendance/fetchLateInfo',
      fetchOverworkInfo: 'attendance/fetchOverworkInfo',
      attendanceRevision: 'attendance/attendanceRevision',
      saveOvertime: 'overtimeModule/saveOvertime',
      fetchDataReleaseNote: 'user/fetchReleaseNote',
      tokenUser: 'user/loadUserFromToken',
    }),

    ...mapMutations({
      setWorkingStatus: 'user/setWorkingStatus',
      setIsActiveInOtherCompany: 'user/setIsActiveInOtherCompany',
      setActivityAttendanceRise: 'activityAttendance/setActivityAttendanceRise',
      setAnnouncement: 'announcement/setAnnouncementPublished',
      setQuoteRise: 'quotes/setQuoteRise',
    }),
    handleFailedCompare(statusCode) {
      this.closeModalFaceCam()
      this.alertCustomError(
        'Failed to Compare Image',
        `Please try to recapture image from settings > general setting. (status code : ${statusCode})`
      )
    },
    async checkReleaseNote() {
      await this.fetchDataReleaseNote()
      const id = parseInt(localStorage.getItem('release_id'))

      // if you already fetch release note data and the result is null
      // the logic stop here
      if (!this.releaseNote) {
        this.isModalReleaseNote = false
        return false
      }

      // fetch data if id for release not set on browser local storage
      if (!id) {
        this.isModalReleaseNote = true
        return false
      }

      // check if release note that is the same as the release note saved in browser storage
      if (id !== this.releaseNote.id) {
        this.isModalReleaseNote = true
        return false
      }
    },

    onConfirmModal(event) {
      if (event.isRelease) {
        localStorage.setItem('release_id', this.releaseNote.id)
        this.isModalReleaseNote = false
      }
    },

    async compareAndClockIn(result) {
      if (result) {
        let location = null
        try {
          location = await this.getLocation()
        } catch (e) {
          this.alertCustomError(
            'Failed to get location.',
            `Please allow geolocation permission in your browser settings. ${e.message}`
          )
        }
        if (location) {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude
          await this.clockIn(latitude, longitude)
          showToast('Clock in success', 'is-success', 'is-bottom')
        }
      } else {
        // show dialog alert
        this.alertCustomError('Face not match', 'Face not match')
      }
      this.closeModalFaceCam()
    },
    openModalFaceCam() {
      this.isCloseFaceCam = false
      this.isModalFaceCamOpen = true
    },

    closeModalFaceCam() {
      this.isCloseFaceCam = true
      this.isModalFaceCamOpen = false
    },

    closeReleaseModal() {
      this.isModalReleaseNote = !this.isModalReleaseNote
    },

    async clockIn(latitude, longitude) {
      let data = {
        locationLatIn: latitude,
        locationLongIn: longitude,
      }
      this.setWorkingStatus('online')

      await this.actionClockIn(data)
        .then(async (response) => {
          // If user is late then open modal fix late attendance
          if (response.data.data.firstClockin && response.data.data.isLate) {
            await this.openModalFixLateAttendance()
          }
          this.timerCount += 0.01
        })
        .catch((err) => {
          // back to offline state
          this.setWorkingStatus('offline')
          if (err.response.data.meta.message) {
            this.alertCustomError(
              'Clock In Failed.',
              err.response.data.meta.message
            )
          } else {
            this.alertCustomError(
              'Clock In Failed',
              'Unexpected error happened. Please look at the console'
            )
            // force render
            this.key++
          }
        })
    },

    async checkUserDetail() {
      let response = await this.tokenUser()

      if (!response.data.isRegistered) {
        this.$router.push('/company')
      }
    },
    async clockOut(latitude, longitude) {
      this.setWorkingStatus('offline')
      let data = {
        locationLatOut: latitude,
        locationLongOut: longitude,
      }
      await this.actionClockOut(data)
        .then(async (response) => {
          const formattedDate = moment().format('YYYY-MM-DD')

          await this.initTimer(formattedDate)

          // If user is overwork then get overwork info
          if (response.data.isOverwork) {
            await this.loadOverworkInfo()

            // If get overwork info success then open modal over clock out
            if (this.overworkInfo) {
              this.openModalOverClockOut()
              this.attendanceId = response.data.data.id
              this.startTime = moment.utc(response.data.data.startTime)
              this.endTime = moment.utc(response.data.data.endTime)
            }
          }
        })
        .catch((err) => {
          // back to online state
          this.setWorkingStatus('online')
          if (err?.response?.data?.meta?.message) {
            this.alertCustomError(
              'Clock Out Failed.',
              err?.response?.data?.meta?.message
            )
          } else {
            this.alertCustomError(
              'Clock Out Failed',
              'Unexpected error happened. Please look at the console'
            )
            // force refresh
            this.key++
          }
        })
    },
    openModalActivityAttendance() {
      this.isModalActivityAttendanceOpen = true
    },

    closeModalActivityAttendance() {
      this.isModalActivityAttendanceOpen = false
    },

    async openModalFixLateAttendance() {
      this.isModalFixLateAttendanceOpen = true
      await this.loadLateInfo()
    },

    closeModalFixLateAttendance() {
      this.isModalFixLateAttendanceOpen = false
      this.lateInfo = null
    },

    openModalOverClockOut() {
      this.isModalOverClockOutOpen = true
    },

    closeModalOverClockOut() {
      this.isModalOverClockOutOpen = false
      this.overworkInfo = null
      this.searchUser = null
      this.overtimeReason = null
      this.additionalApproverId = null
    },

    clockInValidation() {
      if (this.user.isActiveInOtherCompany) {
        this.confirmClockIn()
      } else {
        this.toggleWorking()
      }
    },
    confirmClockIn() {
      this.$buefy.dialog.confirm({
        title: 'Clock In Confirmation',
        message: `You still have active session in another company,
        are you sure you will clock in at this company ?
        Clock in on this company will automatically <b>turn off </b> sessions on other companies.`,
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        type: 'is-primary',
        onConfirm: () => this.toggleWorking(),
      })
    },

    async loadAnnouncement() {
      try {
        const response = await this.fetchAnnouncementPublished({
          perPage: 10,
          page: 1,
          sortField: 'id',
          sortOrder: 'desc',
        })
        this.announcementPublished = response.data.data
      } catch (e) {
        console.log(e)
      }
    },

    async loadQuoteRise() {
      try {
        const response = await this.fetchQuoteRise()
        this.quoteRise = response.data.data
      } catch (e) {
        console.log(e)
      }
    },

    async loadLateInfo() {
      this.isLoadingLateInfo = true
      try {
        this.lateInfo = await this.fetchLateInfo()

        // Default Actual Clock In Value
        let lateDuration = moment(this.lateInfo.lateDuration, 'HH:mm:ss')
        let clockIn = moment(this.lateInfo.clockIn, 'HH:mm:ss')
        this.actualClockIn = new Date(
          moment(clockIn.diff(lateDuration)).local().toISOString()
        )

        this.lateInfo.clockIn = this.lateInfo.date + ' ' + this.lateInfo.clockIn
        this.lateInfo.clockOut = this.lateInfo.clockOut
          ? this.lateInfo.date + ' ' + this.lateInfo.clockIn
          : null
      } catch (e) {
        console.log(e)
      }
      this.isLoadingLateInfo = false
    },

    async loadOverworkInfo() {
      this.isLoadingOverworkInfo = true

      try {
        this.overworkInfo = await this.fetchOverworkInfo()
        this.overtimeHour = this.overworkInfo.overworkHour

        // Rule to limit overtime hour field
        this.overtimeRule =
          'required|integer|max_value:' + this.overworkInfo.overworkHour
      } catch (err) {
        if (err?.response?.data?.message) {
          console.log(err.response.data.message)
        } else {
          console.log(err)
        }
      }

      this.isLoadingOverworkInfo = false
    },

    closeModalAnnouncementDetail() {
      this.isOpenModalAnnouncementDetail = false
    },

    async loadActivityAttendance() {
      this.isActivityAttendanceRiseLoading = true
      try {
        const response = await this.fetchActivityAttendanceRise()
        if (response.data.length > 0) {
          this.dataActivityAttendance = response.data[0].data
          this.activityDuration = response.data[0].totalActivityDurationPerDay
          this.isActivityPlayable = response.data[0].isActivityPlayable === 1
          this.activeActivityLocation = response.data[0].activeActivityLocation
            ? response.data[0].activeActivityLocation
            : '-'
          this.startActivityTime = response.data[0].startTime
          this.endActivityTime = response.data[0].endTime
          this.firstStartActivityTime = response.data[0].firstStartTime

          // if activity is not playable then there must be active activity
          if (!this.isActivityPlayable) {
            this.findActiveActivity(response.data[0])
          }
        }
      } catch (e) {
        console.log(e)
      }
      this.isActivityAttendanceRiseLoading = false
    },

    async getActivityAttendanceType() {
      try {
        let response = await ApiService.get('/api/space-roketin/activity/type')
        this.activityAttendanceType = response.data
      } catch (e) {
        console.log(e)
      }
    },

    formatDurationSecond(durationSecond) {
      let hour = Math.floor(durationSecond / 3600)
      let min = Math.floor((durationSecond % 3600) / 60)
      let second = Math.floor((durationSecond % 3600) % 60)

      return `${hour}h ${min}m ${second}s`
    },

    handleEvents(events) {
      this.currentEvents = events
    },

    parseTime(isoDateString) {
      return moment.utc(isoDateString).local().format('DD MMM YYYY')
    },

    getRunningAttendanceActivityTimer(duration, start, end) {
      if (!start || !end || !duration) {
        throw Error(
          'either parameter duration, start, end cannot be null or nan in function getRunningAttendanceActivityTimer('
        )
      }
      const arr = duration && duration.split(':')
      let seconds = 0
      seconds += parseInt(arr && arr[0]) * 3600
      seconds += parseInt(arr && arr[1]) * 60
      seconds += parseInt(arr && arr[2])

      if (!this.isActivityPlayable) {
        const difference = (end - start) / 1000
        this.activityTimerCount = Math.floor(difference + seconds)
      } else {
        this.activityTimerCount = Math.floor(seconds)
      }
    },

    async getActivityInParameter(activityTypeId, description) {
      this.isSubmitting = true
      let location = null
      try {
        location = await this.getLocation()
      } catch (e) {
        this.alertCustomError(
          'Failed to get location.',
          `Please allow geolocation in your browser settings. ${e.message}`
        )
      }

      if (location) {
        this.isActivityAttendanceRiseLoading = true
        try {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude

          if (this.user && this.user.workingStatus === 'online') {
            let locationLatIn = latitude
            let locationLongIn = longitude
            let form = new FormData()

            form.append('activity_type_id', activityTypeId)
            form.append('location_lat_in', locationLatIn)
            form.append('location_long_in', locationLongIn)
            form.append('description', description)

            await ApiService.post(
              '/api/space-roketin/attendance-activity/in',
              form,
              true
            )

            this.selectedAttendanceType = null
            this.description = null
            this.isActivityPlayable = false
            await this.loadActivityAttendance()
            await this.initActivityTimer()

            this.closeModalActivityAttendance()
          } else {
            showToast(
              'You must clock in first before creating or resuming activity',
              'is-danger',
              'is-bottom'
            )
          }
        } catch (e) {
          console.log(e)
        }

        this.isActivityAttendanceRiseLoading = false
      }

      this.isSubmitting = false
    },

    async submit(event) {
      // find another activity which still playing and stop it!
      let indexOtherActivity = this.dataActivityAttendance.findIndex((el) => {
        return el.isPlayable === 0
      })
      if (indexOtherActivity > -1) {
        await this.pauseActivity(indexOtherActivity)
      }

      await this.getActivityInParameter(event.type, event.desc)
    },

    async resumeActivity(activity, index) {
      // tembak api play
      try {
        await this.getActivityInParameter(
          activity.attendanceActivityType.id,
          activity.attendanceActivityDescription
        )

        if (this.user && this.user.workingStatus === 'online') {
          // ubah state punya activity tersebut is playable jadi false (agar icon berubah jadi pause)
          let temp = [...this.dataActivityAttendance]

          // set all to pause
          temp.forEach((t) => (t.isPlayable = 1))

          // set that activity to play
          temp[index].isPlayable = 0
          this.dataActivityAttendance = [...temp]

          this.isActivityPlayable = false
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
    },

    async pauseActivity(index) {
      // tembak api pause
      let location = null
      try {
        location = await this.getLocation()
      } catch (e) {
        this.alertCustomError(
          'Failed to get location.',
          `Please allow geolocation in your browser settings. ${e.message}`
        )
      }
      if (location) {
        try {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude

          let locationLatOut = latitude
          let locationLongOut = longitude

          let form = new FormData()

          form.append('location_lat_out', locationLatOut)
          form.append('location_long_out', locationLongOut)

          await ApiService.post(
            '/api/space-roketin/attendance-activity/out',
            form,
            true
          )
          this.activeActivity = null

          locationLatOut = null
          locationLongOut = null
          await this.loadActivityAttendance()

          // ubah state punya activity tersebut is playable jadi true supaya icon beruabh kembali jadi play
          let temp = [...this.dataActivityAttendance]

          temp[index].isPlayable = 1
          // ubah state acitivty is playable jadi true supaya tombolnya enabled semua
          this.activityAttendanceTimer = this.activityDuration
          this.isActivityPlayable = true
        } catch (e) {
          console.log(e)
        }
      }
    },

    async toggleActivity(event) {
      this.dataActivityAttendance[event.index].isLoading = true

      if (event.rows.isPlayable) {
        // find another activity which still playing and stop it!
        let indexOtherActivity = this.dataActivityAttendance.findIndex(
          (el, i) => {
            return i !== event.index && el.isPlayable === 0
          }
        )
        if (indexOtherActivity > -1) {
          await this.pauseActivity(indexOtherActivity)
        }

        await this.resumeActivity(event.rows, event.index)
      } else {
        await this.pauseActivity(event.index)
      }

      this.dataActivityAttendance[event.index].isLoading = false
    },

    getRunningTimer(duration, start, end) {
      const arr = duration && duration.split(':')
      let seconds = 0
      seconds += parseInt(arr && arr[0]) * 3600
      seconds += parseInt(arr && arr[1]) * 60
      seconds += parseInt(arr && arr[2])

      if (this.user.workingStatus === 'online') {
        const difference = (end - start) / 1000
        this.timerCount = Math.floor(difference + seconds)
      } else {
        this.timerCount = Math.floor(seconds)
      }
    },

    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!('geolocation' in navigator)) {
          reject(new Error('Geolocation is not available.'))
        }
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos)
          },
          (err) => {
            reject(err)
          }
        )
      })
    },

    async initTimer(formattedDate = moment().format('YYYY-MM-DD')) {
      // for work duration
      this.duration = await this.workDuration(formattedDate)
      this.timer = this.duration
      await this.$store.dispatch('user/loadUserFromToken')

      // get current work duration
      const latestAttendance = moment
        .utc(this.user.latestAttendance.startTime)
        .local()
        .valueOf()
      const currentUnixTime = moment().valueOf()
      this.getRunningTimer(this.duration, latestAttendance, currentUnixTime)
    },

    async initActivityTimer() {
      if (!this.activityDuration) {
        this.activityAttendanceTimer = '00:00:00'
      } else {
        try {
          this.activityAttendanceTimer = this.activityDuration

          const latestActivityAttendance = moment
            .utc(this.startActivityTime)
            .local()
            .valueOf()

          const currentUnixActivityTime = moment().valueOf()
          this.getRunningAttendanceActivityTimer(
            this.activityAttendanceTimer,
            latestActivityAttendance,
            currentUnixActivityTime
          )
        } catch (err) {
          console.log('init activity timer error : ', err)
        }
      }
    },

    async initActiveActivityTimer() {
      if (this.activeActivity) {
        let activeActivity = moment
          .utc(this.startActivityTime)
          .local()
          .valueOf()
        let currentUnixActivityTime = moment().valueOf()

        // get running active activity timer
        let difference = (currentUnixActivityTime - activeActivity) / 1000
        let seconds = this.dataActivityAttendance[this.activeActivity]
          .activityDurationSecond

        this.dataActivityAttendance[
          this.activeActivity
        ].activityDurationSecond = difference + seconds
      }
    },

    async toggleWorking() {
      this.isLoading = true
      let location = null
      try {
        location = await this.getLocation()
      } catch (e) {
        this.alertCustomError(
          'Failed to get location.',
          `Please allow geolocation permission in your browser settings. ${e.message}`
        )
      }
      if (location) {
        try {
          let latitude = location.coords.latitude
          let longitude = location.coords.longitude

          if (this.user && this.user.workingStatus === 'online') {
            await this.clockOut(latitude, longitude)
          } else {
            if (this.isFaceRegMandatory) {
              this.openModalFaceCam()
            } else {
              await this.clockIn(latitude, longitude)
            }
          }
        } catch (e) {
          this.alertCustomError(
            'Clock in failed.',
            e.response && e.response.data.meta.message
          )
        }
      }

      this.isLoading = false
    },

    async saveAttendanceAdjustment(event) {
      this.isSavingAttendanceAdjustment = true

      try {
        await ApiService.post('/api/space-roketin/late-attendance/adjustment', {
          reason: event.lateReason,
          attendance_id: this.lateInfo.attendanceId,
          start_time: convertToUTC(event.actualClockIn).format('HH:mm:ss'),
        })
        this.closeModalFixLateAttendance()
        showToast(
          'Successfully Requested Attendance Adjustment',
          'is-success',
          'is-bottom'
        )
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      this.isSavingAttendanceAdjustment = false
    },

    async reviseAttendance() {
      this.isSavingRequestOvertime = true

      let startTime = this.startTime.format('HH:mm:ss')
      let updateEndTime = this.endTime
        .subtract(this.overworkInfo.overworkDuration)
        .format('HH:mm:ss')

      // prevent end time is not changed less than start time
      if (updateEndTime < startTime) {
        updateEndTime = this.startTime.format('HH:mm:ss')
      }

      let form = {
        start_time: startTime,
        end_time: updateEndTime,
      }

      try {
        const data = { id: this.attendanceId, form }
        await this.attendanceRevision(data)
        await this.initTimer(moment().format('YYYY-MM-DD'))
        showToast(
          'Your Attendance is Successfully Revised !',
          'is-success',
          'is-bottom'
        )
      } catch (e) {
        if (e?.response?.data?.message) {
          showToast(e.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(e)
        }
      }

      this.isSavingRequestOvertime = false
      this.closeModalOverClockOut()
    },

    async saveRequestOvertime(event) {
      this.isSavingRequestOvertime = true

      const form = new FormData()
      form.append('date', this.overworkInfo.date)
      form.append('hours', event.overtimeHour)
      form.append('reason', event.overtimeReason)
      form.append('start_time', event.startTime)
      if (event.additionalApproverId) {
        form.append('additional_approver_id', event.additionalApproverId)
      }

      try {
        await this.saveOvertime(form)
        showToast(
          'Successfully Requested Overtime !',
          'is-success',
          'is-bottom'
        )
      } catch (e) {
        showToast(e.response.message, 'is-danger', 'is-bottom')
      }

      this.isSavingRequestOvertime = false
      this.closeModalOverClockOut()
    },

    async fetchFaceRecogMandatory() {
      try {
        let response = await ApiService.get(
          '/api/space-roketin/settings/face-recognition-mandatory'
        )
        if (response.data[0]) {
          this.isFaceRegMandatory = !!response.data[0].isMandatory
        }
        this.isErrorFetchFaceRegMandatory = false
      } catch (err) {
        this.isErrorFetchFaceRegMandatory = true
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
    },

    alertCustomError(title, message) {
      this.$buefy.dialog.alert({
        title: title,
        message: message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'close',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    formatDate(str, format = 'D MMM') {
      const d = new Date(str)
      return moment(d).format(format)
    },
    formatTime(str) {
      return moment(moment.utc(str).toDate()).format('HH:mm')
    },
    trimEventName(title, name) {
      if (name) {
        return `${name}'s - ( ${title})`
      }
      return title
    },

    formatTimerActivity(value) {
      try {
        let hours = Math.floor(value / 3600)
        let minutes = Math.floor((value % 3600) / 60)
        const seconds = Math.floor(value % 60)

        let _h = hours < 10 ? `0${hours}` : hours
        let _m = minutes < 10 ? `0${minutes}` : minutes
        let _s = seconds < 10 ? `0${seconds}` : seconds
        this.activityAttendanceTimer = `${_h}:${_m}:${_s}`
      } catch (err) {
        this.activityAttendanceTimer = `00:00:00`
        console.log('error while formatting timer activity ', err)
      }
    },
    startActivityTimer() {
      this.activityTimerId = setInterval(() => {
        this.activityTimerCount++
      }, 1000)
    },
    stopActivityTimer() {
      clearInterval(this.activityTimerId)
    },
    startActiveActivityTimer(index) {
      this.activeActvitiyTimerId = setInterval(() => {
        this.dataActivityAttendance[index].activityDurationSecond++
      }, 1000)
    },
    stopActiveActivityTimer() {
      clearInterval(this.activeActvitiyTimerId)
    },
    findActiveActivity(activity) {
      for (let index in activity.data) {
        if (activity.data[index].isPlayable === 0) {
          this.activeActivity = index
          break
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/getUserInfo',
      activityAttendanceRise: 'activityAttendance/getActivityAttendanceRise',
      getAnnouncementPublished: 'announcement/getAnnouncementPublished',
      getQuoteRise: 'quotes/getQuoteRise',
      releaseNote: 'user/getReleaseNote',
    }),

    elapsedWorkTime() {
      const date = new Date(null)
      date.setSeconds(this.workTime / 1000)
      const utc = date.toUTCString()
      return utc.substr(utc.indexOf(':') - 2, 8)
    },
    isStoppable() {
      return !this.working && this.pause
    },
    getUpcomingEvents() {
      const today = moment().format('YYYY-MM-DD')
      function compare(a, b) {
        if (a.start < b.start) {
          return -1
        }
        if (a.start > b.start) {
          return 1
        }
        return 0
      }
      const filtered = this.dataTimeOffForUpcomingEvents
        .filter(
          (el) =>
            new Date(el.start) >= new Date(today) ||
            new Date(el.end) >= new Date(today)
        )
        .sort(compare)

      return filtered
    },
  },
  watch: {
    timerCount(value) {
      if (this.user.workingStatus === 'online') {
        setTimeout(() => {
          this.timerCount++
        }, 1000)
        let hours = 0
        let minutes = 0
        hours = Math.floor(value / 3600)
        minutes = Math.floor((value % 3600) / 60)
        const seconds = Math.floor(value % 60)

        const _h = hours < 10 ? `0${hours}` : hours
        const _m = minutes < 10 ? `0${minutes}` : minutes
        const _s = seconds < 10 ? `0${seconds}` : seconds
        this.timer = `${_h}:${_m}:${_s}`
      }
    },

    isActivityPlayable(val) {
      if (!val) {
        this.startActivityTimer()
      } else {
        this.stopActivityTimer()
      }
    },

    activityTimerCount(value) {
      this.formatTimerActivity(value)
    },

    activeActivity(val) {
      if (val) {
        this.startActiveActivityTimer(val)
      } else {
        this.stopActiveActivityTimer()
      }
    },

    isDefaultWorkHour() {
      this.isRequestOvertime = this.isDefaultWorkHour === '0'
    },
  },
}
</script>

<style lang="scss" scoped>
.faq-button {
  position: fixed;
  height: 3.25em;
  bottom: 2.5em;
  right: 2.5em;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 999;
  border-radius: 15px;
  font-size: 15px;

  a {
    color: white;
    padding: 0.25em 0.5em 0.25em 0.25em;
  }
  .icon {
    position: relative;
    top: 0.2em;
  }
}

.buttons {
  border-bottom: 3px solid rgba(100, 100, 100, 0.1);
}

.section-radio-tooltip {
  position: relative;

  .tooltip-info {
    position: absolute;
    left: 145px;
  }
}
</style>
