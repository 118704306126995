<template>
  <div>
    {{ timer ? timer : '00:00:00' }}
  </div>
</template>

<script>
export default {
  props: ['timer'],
}
</script>
