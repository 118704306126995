<template>
  <div>
    <r-card>
      <div class="rise-card-title pb-4">Today's Activity</div>
      <div class="columns is-multiline">
        <div class="column is-9">
          <div
            class="is-12 has-text-weight-bold mb-0 activity-attendance-timer"
          >
            <counter-timer :timer="activityTimer"></counter-timer>
          </div>
          <div class="is-12 mt-0 activity-attendance-location">
            <b-icon icon="map-marker" custom-size="mdi-18px"></b-icon>
            <span class="has-text-centered">
              {{ activeLocation ? activeLocation : '' }}
            </span>
          </div>
        </div>
        <div class="column is-3">
          <b-button
            tag="button"
            class="is-command center"
            icon-left="plus"
            expanded
            @click="openModal"
          >
            Add Activity
          </b-button>
        </div>
        <div class="column is-12 mt-0 mb-0 pt-0">
          <hr class="black-divider" />
        </div>
        <div class="column is-12 mt-0 pt-0">
          <small-table>
            <template #body>
              <activity-table
                :data="data"
                @toggle="toggle($event)"
              ></activity-table>
            </template>
          </small-table>

          <modal-add-activity
            :activityType="activityType"
            :isModalOpen="isModalOpen"
            :isLoading="isLoading"
            @open="openModal"
            @close="closeModal"
            @submit="submit"
          ></modal-add-activity>
        </div>
      </div>
    </r-card>
  </div>
</template>
<script>
import ActivityTable from '@/components/Rise/ActivityTable.vue'
import SmallTable from '@/components/Rise/SmallTable.vue'
import CounterTimer from './CounterTimer.vue'
import ModalAddActivity from './ModalAddActivity.vue'
export default {
  components: { SmallTable, ActivityTable, CounterTimer, ModalAddActivity },
  props: {
    activityType: Array,
    isModalOpen: Boolean,
    activityTimer: String,
    activeLocation: String,
    data: Array,
    isLoading: Boolean,
  },

  methods: {
    openModal() {
      this.$emit('open')
    },
    closeModal() {
      this.$emit('close')
    },
    toggle(event) {
      this.$emit('toggleActivity', event)
    },
    submit(event) {
      this.$emit('submit', event)
    },
  },
}
</script>
