<template>
  <div>
    <r-page-modal
      :prompt-on-leave="false"
      prompt-message="Are you sure?"
      v-if="isModalOverClockOutOpen"
      @hide="closeModalOverClockOut"
      :is-modal="true"
      key="modalKey"
      class="modal-allowed-capacity prevent-closing"
      remove-scrolling
    >
      <template #page-content>
        <h3 class="modal-title has-text-weight-black">Over Clock Out !</h3>
        <p class="has-text-weight-bold has-text-grey-light pb-2">
          You work for
        </p>
        <div class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column is-6">
                <b-field v-if="overworkInfo">
                  <b-radio
                    v-model="isDefaultWorkHour"
                    size="is-medium"
                    native-value="1"
                  >
                    <div class="has-text-weight-bold is-size-1">
                      {{ overworkInfo.clockoutSchedule }}
                    </div>
                  </b-radio>
                </b-field>
                <b-skeleton width="65%" animated v-else />
              </div>
              <div class="column is-6">
                <b-field v-if="overworkInfo">
                  <b-radio
                    v-model="isDefaultWorkHour"
                    size="is-medium"
                    native-value="0"
                  >
                    <div class="has-text-weight-bold is-size-1 has-text-danger">
                      {{ overworkInfo.totalDuration }}
                    </div>
                  </b-radio>
                </b-field>
                <b-skeleton width="65%" animated v-else />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isRequestOvertime">
          <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            v-if="overworkInfo"
          >
            <form
              @submit.prevent="handleSubmit(saveRequestOvertime)"
              class="is-labe-14px-normal form-timeoff-type"
            >
              <div class="buttons" />
              <h3 class="modal-title has-text-weight-black">
                Request Overtime
              </h3>
              <div class="columns is-multiline">
                <div class="column is-12">
                  <b-field label="Date">
                    <p>
                      {{ formatDate(overworkInfo.date, 'DD MMMM YYYY') }}
                    </p>
                  </b-field>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="overtime duration"
                    v-slot="{ errors }"
                    :rules="rule"
                  >
                    <b-field
                      label="Estimation Duration"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <div class="columns">
                        <div class="column is-one-fifth">
                          <div class="pt-2">
                            {{ overworkInfo.overworkDuration }} &rarr;
                          </div>
                        </div>
                        <div class="column is-3 pl-0">
                          <b-input
                            v-model="overtimeHour"
                            expanded
                            placeholder="e.g. in hour"
                          />
                        </div>
                        <div class="column pl-0">
                          <p class="pt-2">hour(s)</p>
                        </div>
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="start time"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Select Time"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-timepicker
                        placeholder="Click to select time"
                        icon="clock"
                        v-model="startTime"
                        :hour-format="hourFormat"
                        :locale="locale"
                      ></b-timepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12 request-section-radio-tooltip">
                  <b-tooltip
                    position="is-top"
                    class="reimbursement-tooltip-info"
                  >
                    <b-icon
                      icon="information"
                      size="is-small"
                      class="tooltip-date-icon"
                    />
                    <template v-slot:content>
                      <div class="is-flex">
                        After searching your approver, please select the user in
                        the list.
                      </div>
                    </template>
                  </b-tooltip>
                  <b-field label="PIC">
                    <b-autocomplete
                      v-model="searchUser"
                      :data="listUser"
                      placeholder="Search PIC"
                      field="fullName"
                      :loading="isFetchingUser"
                      :check-infinite-scroll="true"
                      icon-right="account"
                      @typing="getUserList"
                      @select="selectUser"
                      @infinite-scroll="getMoreUserList"
                    >
                      <template slot-scope="props">
                        <div class="media">
                          <div class="media-left">
                            <div v-if="props.option.profilePictureUrl">
                              <img
                                class="profile-picture"
                                width="32"
                                :src="props.option.profilePictureUrl"
                              />
                            </div>
                            <div v-else>
                              <img
                                class="profile-picture"
                                v-if="props.option.gender === 'F'"
                                width="32"
                                src="/images/default-profile-picture-female.png"
                              />

                              <img
                                class="profile-picture"
                                v-else
                                width="32"
                                src="/images/default-profile-picture-male.png"
                              />
                            </div>
                          </div>
                          <div class="media-content">
                            {{ props.option.fullName }}
                            <br />
                            <small>
                              {{
                                props.option.positionName
                                  ? props.option.positionName
                                  : '-'
                              }}
                            </small>
                          </div>
                        </div>
                      </template>
                    </b-autocomplete>
                  </b-field>
                </div>

                <div class="column is-12">
                  <ValidationProvider
                    name="overtime reason"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Give Reason Why You Overtime"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="overtimeReason"
                        expanded
                        placeholder="Elaborate your reason here"
                        type="textarea"
                        class="hover"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12 pb-1">
                  <b-button
                    expanded
                    native-type="submit"
                    type="is-primary"
                    class="has-text-weight-bold"
                    :loading="isSavingRequestOvertime"
                  >
                    Send Request
                  </b-button>
                </div>
                <div class="column is-12">
                  <b-button
                    type="is-primary"
                    expanded
                    outlined
                    @click="closeModalOverClockOut"
                  >
                    Already Request Overtime
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-else>
          <div class="columns is-multiline pt-3 pb-1">
            <div class="column is-12">
              <b-button
                expanded
                native-type="submit"
                type="is-primary"
                class="has-text-weight-bold"
                v-if="overworkInfo"
                @click="reviseAttendance"
                :loading="isSavingRequestOvertime"
              >
                Choose Clock Out Time
              </b-button>
              <b-skeleton animated v-else />
            </div>
          </div>
        </div>
      </template>
    </r-page-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'
import ApiService from '../../services/common/api.service'
export default {
  props: [
    'isModalOverClockOutOpen',
    'overworkInfo',
    'overtimeHour',
    'isRequestOvertime',
    'rule',
  ],

  data() {
    return {
      overtimeReason: null,
      isSavingRequestOvertime: false,
      isDefaultWorkHour: 1,
      startTime: null,
      hourFormat: undefined,
      locale: 'en-gb',
      offsetDiff: this.getOffsetDiff(),

      // User Autocomplete
      additionalApproverId: null,
      listUser: [],
      isFetchingUser: false,
      pageUserList: 1,
      lastPageUserList: 1,
      selectedUser: '',
      searchUser: '',
    }
  },

  methods: {
    reviseAttendance() {
      this.$emit('reviseAttendance')
    },

    getOffsetDiff() {
      // if you live in asia/jakarta, offset will be 420 (7 * 60 minute)
      let offsetLocal = moment.tz(new Date(), moment.tz.guess()).utcOffset()
      // if you use timezone asia/tokyo, offset will be 540 (9 * 60)
      let offSetTimeZone = moment(new Date()).utcOffset()

      let diff = offsetLocal - offSetTimeZone
      if (diff < 0) {
        diff *= -1
      }
      // convert from minutes to hours
      diff /= 60
      return diff
    },

    saveRequestOvertime() {
      this.isSavingRequestOvertime = true
      const event = {
        startTime: moment(this.startTime)
          .utc()
          .subtract(this.offsetDiff, 'hours')
          .format('HH:mm:ss'),
        overtimeHour: this.overtimeHour,
        overtimeReason: this.overtimeReason,
        additionalApproverId: this.additionalApproverId,
      }

      this.$emit('saveOvertime', event)
      this.isSavingRequestOvertime = false
    },

    selectUser(option) {
      if (option) {
        this.selectedUser = option
        this.additionalApproverId = option.id
      }
    },

    getUserList: debounce(function (name) {
      this.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      if (this.pageUserList > this.lastPageUserList) {
        return
      }
      this.isFetchingUser = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),

    closeModalOverClockOut() {
      this.$emit('closeModalClockOut')
    },

    initTimer() {
      this.$emit('initTimer')
    },

    formatDate(str, format = 'D MMM') {
      const d = new Date(str)
      return moment(d).format(format)
    },

    formatTime(str) {
      return moment(moment.utc(str).toDate()).format('HH:mm')
    },
  },

  watch: {
    isDefaultWorkHour() {
      this.isRequestOvertime = this.isDefaultWorkHour === '0'
    },
  },
}
</script>
