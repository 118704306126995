<template>
  <div>
    <r-page-modal
      :prompt-on-leave="false"
      prompt-message="Are you sure?"
      v-if="isModalLateOpen"
      @hide="closeModalClockIn"
      :is-modal="true"
      key="modalKey"
      class="modal-allowed-capacity"
      remove-scrolling
    >
      <template #page-content>
        <h3 class="modal-title has-text-weight-black">
          You Are Late !
          <span class="office-pointer-click is-pulled-right">
            <b-icon
              icon="close"
              size="is-small"
              @click.native="closeModalClockIn"
            ></b-icon>
          </span>
        </h3>
        <p class="has-text-centered has-text-weight-bold has-text-grey-light">
          You are late around
        </p>
        <h1 class="has-text-centered has-text-danger" v-if="lateInfo">
          {{ lateInfo.lateDuration }}
        </h1>
        <b-skeleton
          class="pb-2"
          position="is-centered"
          width="25%"
          animated
          v-else
        />
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          v-if="!isLoadingLate"
        >
          <form
            @submit.prevent="handleSubmit(saveAttendanceAdjustment)"
            class="is-labe-14px-normal form-timeoff-type"
          >
            <div class="buttons" />
            <h3 class="modal-title has-text-weight-black">
              Request Attendance Adjustment
            </h3>
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-field label="Date">
                  <p>{{ formatDate(lateInfo.date, 'DD MMMM YYYY') }}</p>
                </b-field>
              </div>
              <div class="column">
                <div class="columns is-mobile">
                  <div class="column is-6">
                    <b-field label="Clock In">
                      <p>
                        {{ formatTime(lateInfo.clockIn) }}
                      </p>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Clock Out">
                      <p>
                        {{ lateInfo.clockOut ? lateInfo.clockOut : '-' }}
                      </p>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <ValidationProvider
                  name="actual clock in"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-field
                    label="My Actual Clock In "
                    :type="errors[0] && 'is-danger'"
                    :message="errors[0]"
                  >
                    <b-timepicker
                      hour-format="24"
                      placeholder="e.g 08:00"
                      icon-right="clock"
                      v-model="actualClockIn"
                    />
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-12">
                <ValidationProvider
                  name="late reason"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-field
                    label="Give Reason Why You Late"
                    :type="errors[0] && 'is-danger'"
                    :message="errors[0]"
                  >
                    <b-input
                      v-model="lateReason"
                      expanded
                      placeholder="Elaborate your reason here"
                      type="textarea"
                      class="hover"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-12 pb-1">
                <b-button
                  expanded
                  native-type="submit"
                  type="is-primary"
                  class="has-text-weight-bold"
                  :loading="isSaving"
                >
                  Send Request
                </b-button>
              </div>
              <div class="column is-12">
                <b-button
                  type="is-primary"
                  expanded
                  outlined
                  @click="closeModalClockIn"
                >
                  Already Request Attendance Adjustment
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </r-page-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
export default {
  props: ['isModalLateOpen', 'lateInfo', 'isLoadingLate', 'isSaving'],

  data() {
    return {
      actualClockIn: null,
      lateReason: null,
    }
  },

  methods: {
    closeModalClockIn() {
      this.$emit('closeModalClockIn')
    },

    saveAttendanceAdjustment() {
      const event = {
        lateReason: this.lateReason,
        actualClockIn: this.actualClockIn,
      }

      this.$emit('saveAttendance', event)
    },
    formatDate(str, format = 'D MMM') {
      const d = new Date(str)
      return moment(d).format(format)
    },
    formatTime(str) {
      return moment(moment.utc(str).toDate()).format('HH:mm')
    },
  },
}
</script>
