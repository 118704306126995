<template>
  <div>
    <r-card class="primary-bg-card dashboard-header p-0 clock-in-card">
      <div class="columns is-vcentered dashboard-header__card">
        <div class="column is-6">
          <div
            class="columns is-align-content-flex is-flex-direction-column is-multiline is-justify-content-center"
          >
            <div class="column is-narrow primary-card-work-status-detail p-0">
              <h3 class="is-size-5 has-text-white is-align-self-flex mb-0">
                {{ date }}
              </h3>
            </div>
            <div class="column is-narrow primary-card-work-status-detail p-0">
              <h1
                class="is-size-1 has-text-weight-black has-text-white is-align-self-flex mt-0 mb-0"
              >
                <counter-timer :timer="timer"></counter-timer>
              </h1>
            </div>
            <div class="column is-narrow primary-card-work-status-detail p-0">
              <div
                class="subtitle is-size-5 is-align-self-flex has-text-white is-white has-text-weight-semibold mt-0"
              >
                {{
                  user && user.workingStatus === 'offline'
                    ? 'Stop Working'
                    : 'Working'
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div v-if="isLoading">
            <Loading :is-loading="isLoading" />
          </div>
          <div v-else class="is-justify-content-flex-end is-flex">
            <div
              v-if="user && user.workingStatus === 'online'"
              @click="toggleWorking"
              class="pointer-click"
            >
              <img
                src="/images/check-out.png"
                class="is-align-self-flex-end clock-in-button"
                alt="pause-circle"
              />
            </div>
            <div
              v-if="user && user.workingStatus === 'offline'"
              @click="clockInValidation"
              class="pointer-click"
            >
              <img
                src="/images/check-in.png"
                class="is-align-self-flex-end clock-in-button"
                alt="play-circle"
              />
            </div>
          </div>
        </div>
      </div>
    </r-card>

    <modal-late-clock-in
      :isModalLateOpen="isModalLateOpen"
      :lateInfo="lateInfo"
      :isLoadingLate="isLoading"
      :isSaving="isSaving"
      @saveAttendance="saveAttendance($event)"
      @closeModalClockIn="closeModalClockIn"
    ></modal-late-clock-in>
    <modal-over-clock-out
      :isModalOverClockOutOpen="isModalOverClockOutOpen"
      :overworkInfo="overworkInfo"
      :isRequestOvertime="isRequestOvertime"
      :rule="rule"
      :overtimeHour="overtimeHour"
      @reviseAttendance="reviseAttendance"
      @saveOvertime="saveOvertime"
      @closeModalClockOut="closeModalClockOut"
    ></modal-over-clock-out>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import ModalLateClockIn from './ModalLateClockIn.vue'
import ModalOverClockOut from './ModalOverClockOut.vue'
import CounterTimer from './CounterTimer.vue'
export default {
  props: [
    'date',
    'timer',
    'user',
    'isLoading',

    // late clock in
    'isLoadingLate',
    'isModalLateOpen',
    'lateInfo',
    'isSaving',

    // overwork clock out
    'overworkInfo',
    'overtimeHour',
    'isModalOverClockOutOpen',
    'isRequestOvertime',
    'rule',
  ],
  components: { Loading, ModalLateClockIn, ModalOverClockOut, CounterTimer },

  methods: {
    toggleWorking() {
      this.$emit('toggleWorking')
    },

    clockInValidation() {
      this.$emit('clockInValidation')
    },

    saveAttendance(event) {
      this.$emit('saveAttendance', event)
    },

    saveOvertime(event) {
      this.$emit('saveOvertime', event)
    },

    reviseAttendance() {
      this.$emit('reviseAttendance')
    },

    closeModalClockIn() {
      this.$emit('closeModalClockIn')
    },

    closeModalClockOut() {
      this.$emit('closeModalClockOut')
    },

    initTimer(event) {
      this.$emit('initTimer', event)
    },
  },
}
</script>
