<template>
  <div>
    <Modal
      :title="'Add New Activity'"
      :show="isModalOpen"
      @hide="closeModal"
      :class="'modal-allowed-capacity'"
    >
      <template #form>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(submit($event))"
            class="is-labe-14px-normal form-timeoff-type"
          >
            <div class="columns is-multiline">
              <div class="column is-12">
                <ValidationProvider
                  name="activity type"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-field
                    label="Activity Type"
                    :type="errors[0] && 'is-danger'"
                    :message="errors[0]"
                  >
                    <b-select
                      v-model="selectedType"
                      expanded
                      placeholder="Select Attendance Type"
                      class="hover"
                    >
                      <option
                        v-for="option in activityType"
                        :value="option.id"
                        :key="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-12">
                <ValidationProvider
                  name="activity description"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-field
                    label="Activity Type"
                    :type="errors[0] && 'is-danger'"
                    :message="errors[0]"
                  >
                    <b-input
                      v-model="description"
                      expanded
                      placeholder="Reason"
                      type="textarea"
                      class="hover"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-12">
                <b-button
                  expanded
                  native-type="submit"
                  type="is-primary"
                  class="has-text-weight-bold"
                  :loading="isLoading"
                >
                  Add
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '../Modals/Modal.vue'

export default {
  props: {
    activityType: Array,
    isModalOpen: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      description: null,
      selectedType: null,
    }
  },
  methods: {
    /**
     * Open Modal listener
     */
    openModal() {
      this.$emit('open')
    },
    /**
     * Close Modal listener
     */
    closeModal() {
      this.$emit('close')
    },

    /**
     * Submit data emitter
     */
    submit() {
      const event = {
        type: this.selectedType,
        desc: this.description,
      }
      this.$emit('submit', event)
    },
  },
  components: { Modal },
}
</script>
